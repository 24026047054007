import Icon from '@/components/icon'
import { CurrencyAssetListEnum, TabType } from '@/constants/assets/fiat'
import { useEffect, useState } from 'react'
import { getFiatPayDefaultCurrency } from '@/apis/assets/fiat'
import { t } from '@lingui/macro'
import { DefaltCurrencyType } from '@/typings/api/assets/fiat'
import { formatCurrency } from '@/helper/decimal'
import { FiatDepositLayoutModal } from '@/features/user/components/sidebar/fiat-deposit-layout-modal'
import MultiFiatWithdraw from './components/multi-fiat-withdraw'
import style from './index.module.css'

function MultiCurrency(props) {
  const { tab, c2c, thirdParty, ncPlatform, onClickCell, currentTab, paymentDealType } = props
  const isDeposit = [CurrencyAssetListEnum.fiatCurrencyWithdrawal, CurrencyAssetListEnum.fillOutDeposit]
  const [tradingData, setTradingData] = useState({
    list: [] as DefaltCurrencyType[],
    isShowModal: false as boolean,
    currencyData: {} as DefaltCurrencyType,
    withdrawShowModal: false as boolean,
  })

  /** 获取法币充提交易区 */
  const queryFiatPayDefaultCurrency = async () => {
    const res = await getFiatPayDefaultCurrency({})
    if (res.isOk && res?.data) {
      setTradingData({ ...tradingData, list: res?.data?.list || [] })
    }
  }

  /** 法币充提事件 */
  const onDepositEvent = event => {
    const isWithdrawTab = currentTab === TabType.withdraw
    setTradingData({
      ...tradingData,
      withdrawShowModal: isWithdrawTab,
      isShowModal: !isWithdrawTab,
      currencyData: event,
    })
  }

  useEffect(() => {
    queryFiatPayDefaultCurrency()
  }, [])

  return tab?.withdraw?.map((item, itemIndex) => (
    <div key={String(itemIndex)} className="mb-6">
      {(c2c || thirdParty || ncPlatform) && <p className="deposit-title">{item.title}</p>}
      {item?.items?.map(
        (i, index) =>
          !isDeposit.includes(i?.type) &&
          i?.show && (
            <div className="deposit-box deposit-pt" key={index} onClick={() => onClickCell(i)}>
              <Icon name={i.icon} className="icon-assets" />
              <div className="deposit-box-middle">
                <p className="title">{i.title}</p>
                <p className="deposit-context">{i.description}</p>
              </div>
              <Icon name="transaction_arrow" className={'arrow-icon'} />
            </div>
          )
      )}
      {tradingData?.list?.map((currencyRow, index) => {
        return (
          <div className={`${style['scoped-trading']}`} key={index}>
            <div className="trading-box trading-pt" onClick={() => onDepositEvent(currencyRow)}>
              <Icon name={'icon_web_revision_chongzhi'} className="trading-icon" />
              <div className="trading-box-middle">
                <p className="title">{`${currencyRow?.areaCurrency || ''} ${
                  currentTab === TabType.withdraw
                    ? t`features_user_components_sidebar_index_etltjevzl9`
                    : t`features_home_hero_banner_index_flh6elz9z2`
                }`}</p>
                <p className="deposit-context">{t`features_user_components_sidebar_index_kb_ytyt9tr`}</p>
              </div>
              <Icon name="transaction_arrow" className={'trading-arrow-icon'} />
            </div>

            {currentTab === TabType.withdraw && (
              <div className="trading-balance">
                {`
                 ${t`features_c2c_center_ad_account_index_jt4f0zqkzqepc1z0udcpb`} ${
                  currencyRow?.symbol || ''
                } ${formatCurrency(currencyRow?.amount || 0, Number(currencyRow?.coinPrecision), false)}`}
              </div>
            )}
          </div>
        )
      })}

      {tradingData?.isShowModal && (
        <FiatDepositLayoutModal
          paymentDealType={paymentDealType}
          isShowModal={tradingData?.isShowModal}
          fiatCode={tradingData?.currencyData?.areaCurrency || ''}
          setClose={() => {
            setTradingData({ ...tradingData, isShowModal: false })
          }}
        />
      )}

      {tradingData?.withdrawShowModal && (
        <MultiFiatWithdraw
          areaCurrencyData={tradingData?.currencyData}
          withdrawShowModal={tradingData?.withdrawShowModal}
          onclose={() => {
            setTradingData({ ...tradingData, withdrawShowModal: false })
          }}
        />
      )}
    </div>
  ))
}

export default MultiCurrency

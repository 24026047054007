/** 交易区组件 */
import { Select } from '@nbit/arco'
import { useEffect, useState } from 'react'
import { t } from '@lingui/macro'
import { FinanceOrdersAreaListType, FinanceOrdersCoinListType } from '@/typings/api/assets/fiat'
import { getFinanceOrdersAreaList } from '@/apis/assets/fiat'
import { useAssetsStore } from '@/store/assets'
import { usePersonalCenterStore } from '@/store/user/personal-center'
import style from './index.module.css'

const Option = Select.Option

function MultiTradingArea(props) {
  const { queryFinanceOrdersInfoV2 } = props
  const { fiatCurrencyData } = usePersonalCenterStore()
  const [tradingAreaInfo, setTradingAreaInfo] = useState({
    areaData: {} as FinanceOrdersAreaListType,
    coinList: [] as FinanceOrdersCoinListType[],
    coinData: {} as FinanceOrdersCoinListType,
    areaList: [] as FinanceOrdersAreaListType[],
  })

  /** 默认选中交易区方法 */
  const onSelectTradingArea = data => {
    return data?.find(row => fiatCurrencyData?.currencyTypeCd === row?.areaCurrency) || data?.[0] || {}
  }
  /** 默认选中支持币种 */
  const onSelectCoin = data => {
    return data?.find(row => row?.fiatCoin === data?.areaCurrency) || data?.[0] || {}
  }

  /** 币种选中事件  */
  const onSelectedPartCode = event => {
    setTradingAreaInfo({ ...tradingAreaInfo, coinData: event })
    queryFinanceOrdersInfoV2(event?.fiatCoin, tradingAreaInfo?.areaData?.areaCurrency)
  }

  /** 代码选中部分 */
  const onChange = event => {
    const coin = tradingAreaInfo?.areaList?.filter(row => row?.areaCurrency === event)?.[0]?.coinList || []
    setTradingAreaInfo({
      ...tradingAreaInfo,
      areaData: { ...tradingAreaInfo.areaData, areaCurrency: event },
      coinList: coin,
      coinData: onSelectCoin(coin),
    })

    queryFinanceOrdersInfoV2(onSelectCoin(coin)?.fiatCoin, event)
  }

  /** 获取交易区 */
  const queryFinanceOrdersAreaList = async () => {
    const res = await getFinanceOrdersAreaList({})
    if (res.isOk && res?.data) {
      const data = res?.data?.list || []
      const trade = onSelectTradingArea(data)
      const coin = onSelectCoin(trade?.coinList || [])
      setTradingAreaInfo({
        ...tradingAreaInfo,
        areaList: data,
        coinList: trade?.coinList || [],
        coinData: coin || {},
        areaData: trade as FinanceOrdersAreaListType,
      })

      queryFinanceOrdersInfoV2(coin?.fiatCoin, trade?.areaCurrency)
    }
  }
  useEffect(() => {
    queryFinanceOrdersAreaList()
  }, [])
  return (
    <div className={style['scoped-area']}>
      <div className="area-box">
        <div className="area-box-text">{t`order.filters.tradeArea.tradeArea`}</div>
        <div>
          <Select style={{ width: 78 }} onChange={e => onChange(e)} value={tradingAreaInfo?.areaData?.areaCurrency}>
            {tradingAreaInfo?.areaList?.map((option, index) => (
              <Option key={index} value={option?.areaCurrency || ''}>
                {option?.areaCurrency || ''}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="area-currency-box">
        <div className="area-currency">{t`features_user_components_sidebar_fill_out_form_components_trading_area_index_6pijetpuy9`}</div>
        {tradingAreaInfo?.coinList?.map((res, key) => {
          return (
            <div
              className={`area-select-accite ${
                res?.coinId === tradingAreaInfo?.coinData?.coinId ? 'area-select-default' : ''
              }`}
              key={key}
              onClick={() => onSelectedPartCode(res)}
            >
              {res?.fiatCoin || ''}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MultiTradingArea

import { getFiatPayPaymentList, getFiatPayPaymentReciveList, getFiatPayWithdrawInfo } from '@/apis/assets/fiat'
import CustomModal from '@/features/agent/modal'
import { FiatWithdrawLayout } from '@/features/assets/main/withdraw/fiat-withdraw'
import { AllCurrencyListResp, WithdrawInfoResp } from '@/typings/api/assets/fiat'
import { useAssetsFiatStore } from '@/store/assets/fiat'
import { useEffect, useState } from 'react'
import { AvailableAreaItem, Payment } from '@/typings/api/assets/fiat-payment'

function MultiFiatWithdraw(props) {
  const { withdrawShowModal, areaCurrencyData, onclose } = props
  const [withdrawalData, setWithdrawalData] = useState({
    withdrawalCoinList: [] as AllCurrencyListResp[],
    withdrawalCoinData: {} as WithdrawInfoResp,
    paymentsList: [] as Payment[],
  })
  const [coinList, setCoinList] = useState<AvailableAreaItem[]>([])
  const [updatedPaymentsListData, seUpdatedPaymentsListData] = useState<AvailableAreaItem[]>([])

  const { faitEnums } = useAssetsFiatStore() || {}
  const paymentColor = faitEnums.c2cPaymentColorEnum.enums
  const paymentMethod = faitEnums.paymentDicCodeEnum.enums
  const paymentTypeEnum = faitEnums.c2cPaymentTypeEnum.enums

  let accountInfo = [] as Payment[]

  /** 根据交易区获取 提现币种信息 */
  const queryFiatPayWithdrawInfo = async () => {
    const res = await getFiatPayWithdrawInfo({
      areaCurrency: areaCurrencyData?.areaCurrency,
    })
    if (res.isOk && res?.data) {
      setWithdrawalData({
        ...withdrawalData,
        withdrawalCoinList: res?.data?.list || [],
        withdrawalCoinData:
          res?.data?.list?.find(coinInfo => coinInfo?.fiatCode === areaCurrencyData?.areaCurrency) ||
          res?.data?.list?.[0] ||
          {},
      })
    }
  }

  /** 选中法币事件 */
  const onCoinEvent = event => {
    const valData = withdrawalData?.withdrawalCoinList?.find(coinInfo => coinInfo?.fiatCode === event) || {}
    setWithdrawalData({ ...withdrawalData, withdrawalCoinData: valData })
  }
  /** 收款方式列表数据聚合  */
  const paymentMethodAggregation = (list?) => {
    return list?.map(item => {
      const paymentDealTypeInfo = paymentMethod?.find(dealType => dealType?.codeVal === item?.paymentTypeCd)
      const paymentColorInfo = paymentColor?.find(color => color?.codeVal === item?.paymentTypeCd)
      const label = paymentDealTypeInfo ? paymentDealTypeInfo?.codeKey : item?.paymentTypeCd
      const color = paymentColorInfo ? paymentColorInfo?.codeKey : ''

      return {
        label,
        color,
        value: item?.paymentTypeCd,
        id: item?.id,
      }
    })
  }

  /** 获取币种支付方式 */
  const queryFiatPayPaymentList = async () => {
    const res = await getFiatPayPaymentList({})
    if (res.isOk && res?.data) {
      setCoinList(res?.data || [])
    }
  }

  /** 收款方式数据聚合方法 */
  const paymentMethodDataAggregationMethod = (paymentsAreaData, paymentsList) => {
    const updatedPaymentsList = paymentsAreaData?.payments?.map(item => {
      const paymentDealTypeInfo = paymentTypeEnum?.find(dealType => dealType?.codeVal === item)
      const paymentColorInfo = paymentColor?.find(color => color?.codeVal === item)
      accountInfo = paymentsList?.filter(account => account?.paymentTypeCd === item) || []
      return {
        label: paymentDealTypeInfo ? paymentDealTypeInfo?.codeKey : item,
        color: paymentColorInfo ? paymentColorInfo?.codeKey : '',
        value: item,
        account: accountInfo && accountInfo?.[0]?.account ? accountInfo?.[0]?.account : '',
        qrCodeAddr: accountInfo && accountInfo?.[0]?.qrCodeAddr ? accountInfo?.[0]?.qrCodeAddr : '',
        IsShowRight: accountInfo?.length > 1,
        id: accountInfo && accountInfo?.[0]?.id ? accountInfo?.[0]?.id : '',
        accountInfo,
        currencyName: paymentsAreaData?.currencyName,
      }
    })

    updatedPaymentsList?.forEach((paymentsInfo, index) => {
      paymentsInfo?.accountInfo?.forEach(val => {
        val.newIndex = index
      })
    })

    return updatedPaymentsList
  }

  /** 获取收款方式列表 */
  const queryFiatPayPaymentReciveList = async () => {
    const res = await getFiatPayPaymentReciveList({
      coinId: withdrawalData?.withdrawalCoinData?.id,
    })

    if (res.isOk && res?.data) {
      setWithdrawalData({ ...withdrawalData, paymentsList: res?.data?.recivePaymentList || [] })
    }
  }

  useEffect(() => {
    queryFiatPayPaymentList()
    queryFiatPayWithdrawInfo()
  }, [])

  useEffect(() => {
    withdrawalData?.withdrawalCoinData?.fiatCode && queryFiatPayPaymentReciveList()
  }, [withdrawalData?.withdrawalCoinData?.fiatCode])

  useEffect(() => {
    if (withdrawalData?.paymentsList?.length > 0 && coinList?.length > 0) {
      const paymentsAreaData = coinList?.find(val => val.coinSymbol === withdrawalData?.withdrawalCoinData?.fiatCode)
      seUpdatedPaymentsListData(paymentMethodDataAggregationMethod(paymentsAreaData, withdrawalData?.paymentsList))
    }
  }, [withdrawalData?.paymentsList, coinList])

  return (
    <CustomModal style={{ width: 600 }} visible={withdrawShowModal}>
      {withdrawShowModal && (
        <FiatWithdrawLayout
          max={String(withdrawalData?.withdrawalCoinData?.max) || '10000000000'}
          min={String(withdrawalData?.withdrawalCoinData?.min) || '0'}
          withdrawalCoinList={withdrawalData?.withdrawalCoinList}
          firstFiatCode={areaCurrencyData?.areaCurrency}
          defaultValueCurrency={withdrawalData?.withdrawalCoinData}
          cryptCode={''}
          paymentTypeFilterLists={paymentMethodAggregation(withdrawalData?.paymentsList) || []}
          payments={updatedPaymentsListData || []}
          currencyFiat={''}
          isDefault={false}
          changeLegalCurrencyId={() => {}}
          changeCharge={onCoinEvent}
          areaCurrencyData={areaCurrencyData}
          onclick={() => {
            onclose()
          }}
          changeAccount={() => {}}
        />
      )}
    </CustomModal>
  )
}

export default MultiFiatWithdraw

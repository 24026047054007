import Icon from '@/components/icon'

function SingleCurrency(props) {
  const { tab, c2c, thirdParty, ncPlatform, onClickCell } = props
  return tab?.withdraw?.map((item, itemIndex) => (
    <div key={String(itemIndex)} className="mb-6">
      {(c2c || thirdParty || ncPlatform) && <p className="deposit-title">{item.title}</p>}
      {item?.items?.map(
        (i, index) =>
          i.show && (
            <div className="deposit-box deposit-pt" key={index} onClick={() => onClickCell(i)}>
              <Icon name={i.icon} className="icon-assets" />
              <div className="deposit-box-middle">
                <p className="title">{i.title}</p>
                <p className="deposit-context">{i.description}</p>
              </div>
              <Icon name="transaction_arrow" className={'arrow-icon'} />
            </div>
          )
      )}
    </div>
  ))
}

export default SingleCurrency

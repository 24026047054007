import { Modal } from '@nbit/arco'
import { Payment, paymentType } from '@/typings/api/assets/fiat-payment'
import style from './index.module.css'

type AccountPopType = {
  /** 弹窗参数 */
  visiblePop?: boolean
  /** 清除方法 */
  onvisibleClose: () => void
  /** 收款账户显示数据 */
  payments?: paymentType
  /** 账户信息显示方法 */
  getUserAccount: (e: paymentType) => void
  /** 默认选中值 */
  defaultAccount?: string
  /** 当前值选中方法 */
  onAccountInfo: (e: paymentType, index: number) => void
  /** 子账户当前值信息 */
  oldObj?: Payment
}

function AccountPop(props: AccountPopType) {
  const { visiblePop, onvisibleClose, payments, getUserAccount, defaultAccount, onAccountInfo, oldObj } = props

  return (
    <Modal
      title={payments?.label}
      visible={visiblePop}
      onCancel={() => onvisibleClose()}
      footer={null}
      autoFocus={false}
      focusLock
    >
      <div className={style['all-payments-box']}>
        {payments?.accountInfo &&
          payments?.accountInfo.map((res, index) => {
            return (
              <div
                onClick={() => {
                  onAccountInfo(res, index)
                }}
                className={`payment-manage-box ${
                  res?.id === (oldObj?.id || defaultAccount) && 'payment-manage-active'
                }`}
                key={index}
              >
                {getUserAccount(res)}
              </div>
            )
          })}
      </div>
    </Modal>
  )
}

export default AccountPop
